<template>
  <div
    :class="[
      'input',
      { 'has-error': error },
      { 'has-prefix': prefix },
      { 'has-suffix': suffix },
      { [`input--${variant}`]: !!variant },
    ]"
  >
    <label :for="id || name" v-if="label" class="input__label">
      {{ label }}
      <p v-if="info" class="input__info">{{ info }}</p>
    </label>
    <div class="input__content">
      <span v-if="prefix" class="input__prefix" v-html="prefix" />
      <input
        :class="['input__input', { [`text-${textAlign}`]: !!textAlign }]"
        :id="id || name"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength"
        :readonly="readonly"
        ref="input"
        @input="updateInput"
        @change="changeInput"
      />
      <span v-if="suffix" class="input__suffix" v-html="suffix" />
    </div>
    <p v-if="error" class="input__error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'Input',
  // inject: ['$validator'],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    id: String,
    label: String,
    placeholder: String,
    info: String,
    name: {
      type: String,
      required: true,
    },
    autocomplete: String,
    value: String,
    error: String,
    prefix: String,
    suffix: String,
    rows: {
      type: String,
      default: '5',
    },
    maxlength: [Number, String],
    variant: {
      validator: function (value) {
        return ['independent'].includes(value);
      },
    },
    readonly: Boolean,
    textAlign: {
      validator: function (value) {
        return ['center', 'right'].includes(value);
      },
    },
  },
  methods: {
    updateInput(e) {
      this.$emit('input', e.target.value);
    },
    changeInput(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss">
.input__label {
  display: block;
  font-size: $font-size-small;
  margin-bottom: rem(4px);
}

.input__content {
  position: relative;
  display: flex;
  align-items: center;

  .input.has-prefix &,
  .input.has-suffix & {
    background: $color-grey-light;
  }
}

.input__prefix {
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: 1;
  padding-left: rem(6px);
  padding-right: rem(2px);
  margin-top: rem(2px);
}

.input__suffix {
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: 1;
  padding-left: rem(3px);
  padding-right: rem(6px);
  margin-top: rem(2px);
}

.input__input {
  appearance: none;
  display: block;
  border-radius: 0;
  border: rem(1px) solid transparent;
  background: $color-grey-light;
  font-size: $font-size-base-rem;
  line-height: 1;
  padding: rem(10px) rem(6px);
  width: 100%;
  min-width: rem(110px);

  .input.has-error & {
    border-color: $color-error;
  }

  .input.has-prefix & {
    padding-left: rem(1px);
  }

  .input.has-suffix & {
    padding-right: rem(3px);
  }

  .input--independent & {
    border-color: $color-grey-border;
    border-radius: $border-radius;
  }

  &:focus {
    border-color: $color-primary;
    outline: none;
  }
}

.input__input::placeholder {
  color: $color-grey;
  font-weight: $font-weight-thin;
  font-size: $font-size-base-rem;
}

.input__info {
  color: $color-grey-dark;
  font-size: $font-size-extra-small;
  margin-top: rem(4px);
}

.input__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
}
</style>
