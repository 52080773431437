<template>
  <div class="summary">
    <Margins>
      <h4>{{ $t('SUMMARY') }}</h4>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th />
              <th>{{ $t('FOR_MANUFACTURER') }}</th>
              <th></th>
              <th>{{ $t('FOR_DEALER') }}</th>
              <th></th>
            </tr>
            <tr>
              <th>{{ $t('CUSTOMER_VALUE') }}</th>
              <th>{{ $t('TURNOVER') }}</th>
              <th>{{ $t('MARGIN') }}</th>
              <th>{{ $t('TURNOVER') }}</th>
              <th>{{ $t('MARGIN') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(bundle, index) in bundles" :key="index">
              <td>{{ $t('BUNDLE') }} {{ index + 1 }}</td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTurnoverForManufacturer(bundle) }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateMarginForManufacturer(bundle) }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTurnoverForDealer(bundle) }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateMarginForDealer(bundle) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-right">{{ $t('TOTAL') }}</td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTotalTurnoverForManufacturer() }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTotalMarginForManufacturer() }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTotalTurnoverForDealer() }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateTotalMarginForDealer() }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Margins>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  calculateTurnoverForManufacturer,
  calculateTotalTurnoverForManufacturer,
  calculateMarginForManufacturer,
  calculateTotalMarginForManufacturer,
  calculateTurnoverForDealer,
  calculateTotalTurnoverForDealer,
  calculateMarginForDealer,
  calculateTotalMarginForDealer,
} from '@/calculator';
import { Margins } from '@/components';

export default {
  name: 'Summary',
  components: {
    Margins,
  },
  computed: {
    ...mapState(['bundles']),
  },
  methods: {
    calculateTurnoverForManufacturer(bundle) {
      return calculateTurnoverForManufacturer(bundle);
    },
    calculateTotalTurnoverForManufacturer() {
      return calculateTotalTurnoverForManufacturer(this.bundles);
    },
    calculateMarginForManufacturer(bundle) {
      return calculateMarginForManufacturer(bundle);
    },
    calculateTotalMarginForManufacturer() {
      return calculateTotalMarginForManufacturer(this.bundles);
    },
    calculateTurnoverForDealer(bundle) {
      return calculateTurnoverForDealer(bundle);
    },
    calculateTotalTurnoverForDealer() {
      return calculateTotalTurnoverForDealer(this.bundles);
    },
    calculateMarginForDealer(bundle) {
      return calculateMarginForDealer(bundle);
    },
    calculateTotalMarginForDealer() {
      return calculateTotalMarginForDealer(this.bundles);
    },
  },
};
</script>

<style lang="scss">
.summary {
  background: $color-white;
  padding: 1rem;
  border-radius: $border-radius;

  @include min-width(md) {
    padding: 2rem;
  }
}
</style>
