import { BANK_PRICING_SWEDEN, BANK_PRICING_DENMARK } from '@/constants';

// https://www.jacklmoore.com/notes/rounding-in-javascript/
function roundToTwo(value) {
  return Number(Math.round(value + 'e2') + 'e-2');
}

export const calculateManufacturingCostPerUnit = (bundle) => {
  let sum = 0;

  bundle.products.forEach((product) => {
    sum += +product.units * +product.manufacturingCostPerUnit;
  });

  return roundToTwo(sum);
};

export const calculateDealerCostPerUnit = (bundle) => {
  let sum = 0;

  bundle.products.forEach((product) => {
    sum += +product.units * +product.dealerCostPerUnit;
  });

  bundle.services.forEach((service) => {
    sum += +service.units * +service.dealerCostPerUnit;
  });

  return roundToTwo(sum);
};

export const calculateRetailValue = (bundle) => {
  let sum = 0;

  bundle.products.forEach((product) => {
    sum += +product.units * +product.retailValue;
  });

  bundle.services.forEach((service) => {
    sum += +service.units * +service.retailValue;
  });

  return roundToTwo(sum);
};

export const calculateFinancingCostCompensation = (bundle) => {
  const bankPricing =
    process.env.VUE_APP_THEME === 'stihl'
      ? BANK_PRICING_DENMARK(+bundle.financingPeriod)
      : BANK_PRICING_SWEDEN(+bundle.financingPeriod);

  return roundToTwo(bankPricing * calculateRetailValue(bundle));
};

export const calculateMonthlyPaymentForCustomer = (bundle) => {
  return roundToTwo(
    (calculateRetailValue(bundle) - +bundle.directSubsidyFromManufacturer) /
      +bundle.financingPeriod,
  );
};

export const calculateOutstandingLoanOnRenewal = (bundle) => {
  return roundToTwo(
    (+bundle.financingPeriod - +bundle.timeToRenew) *
      calculateMonthlyPaymentForCustomer(bundle),
  );
};

export const calculateOutstandingLoanOnRenewalCompensation = (
  previousBundle,
) => {
  return roundToTwo(
    calculateOutstandingLoanOnRenewal(previousBundle) -
      previousBundle.inAdvanceCommittedPriceForUsedProduct,
  );
};

export const calculateTurnoverForManufacturer = (bundle) => {
  let sum = 0;

  bundle.products.forEach((product) => {
    sum += +product.units * +product.dealerCostPerUnit;
  });

  return roundToTwo(sum);
};

export const calculateTotalTurnoverForManufacturer = (bundles) => {
  let sum = 0;

  bundles.forEach((bundle) => {
    sum += calculateTurnoverForManufacturer(bundle);
  });

  return roundToTwo(sum);
};

export const calculateMarginForManufacturer = (bundle) => {
  let sum = 0;

  bundle.products.forEach((product) => {
    sum +=
      +product.units *
      (+product.dealerCostPerUnit - +product.manufacturingCostPerUnit);
  });

  sum -= +bundle.directSubsidyFromManufacturer;

  return roundToTwo(sum);
};

export const calculateTotalMarginForManufacturer = (bundles) => {
  let sum = 0;

  bundles.forEach((bundle) => {
    sum += calculateMarginForManufacturer(bundle);
  });

  return roundToTwo(sum);
};

export const calculateTurnoverForDealer = (bundle) => {
  const sum =
    calculateRetailValue(bundle) -
    bundle.financingCostCompensation -
    bundle.outstandingLoanOnRenewalCompensation;

  return roundToTwo(sum);
};

export const calculateTotalTurnoverForDealer = (bundles) => {
  let sum = 0;

  bundles.forEach((bundle) => {
    sum += calculateTurnoverForDealer(bundle);
  });

  return roundToTwo(sum);
};

export const calculateMarginForDealer = (bundle) => {
  const sum =
    calculateRetailValue(bundle) -
    calculateDealerCostPerUnit(bundle) -
    bundle.financingCostCompensation -
    bundle.outstandingLoanOnRenewalCompensation;

  return roundToTwo(sum);
};

export const calculateTotalMarginForDealer = (bundles) => {
  let sum = 0;

  bundles.forEach((bundle) => {
    sum += calculateMarginForDealer(bundle);
  });

  return roundToTwo(sum);
};
