<template>
  <div class="margins">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Margins',
};
</script>

<style lang="scss">
.margins > * + * {
  margin-top: 1rem;
}

.margins__one {
  margin-top: 1rem;
}

.margins__double {
  margin-top: 2rem;
}

.margins__triple {
  margin-top: 3rem;
}

.margins__mobile-md-double {
  margin-top: 2rem;

  @include min-width(md) {
    margin-top: 0;
  }
}
</style>
