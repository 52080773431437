<template>
  <div class="bundle">
    <Margins>
      <h4 class="bundle__header">
        {{ $t('BUNDLE') }} {{ index + 1 }}
        <Button size="small" variant="ghost" @click="removeBundle">
          <template v-slot:icon>
            <RemoveSVG class="icon" />
          </template>
          {{ $t('REMOVE_BUNDLE') }}
        </Button>
      </h4>

      <BundleTable :index="index" :bundle="bundle" />
      <BundleSummary :index="index" :bundle="bundle" class="margins__double" />
    </Margins>
  </div>
</template>

<script>
import { types } from '@/store';
import { mapActions } from 'vuex';
import BundleTable from './BundleTable.vue';
import BundleSummary from './BundleSummary.vue';
import { Margins, Button } from '@/components';
import RemoveSVG from '@/assets/images/times-circle-solid.svg';

export default {
  name: 'Bundle',
  components: {
    BundleTable,
    BundleSummary,
    Margins,
    Button,
    RemoveSVG,
  },
  props: {
    index: Number,
    bundle: Object,
  },
  methods: {
    ...mapActions([types.REMOVE_BUNDLE]),
    removeBundle() {
      this.REMOVE_BUNDLE(this.index);
    },
  },
};
</script>

<style lang="scss">
.bundle {
  background: $color-white;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: $border-radius;

  @include min-width(md) {
    padding: 2rem;
  }
}

.bundle__header {
  display: flex;
  justify-content: space-between;
}
</style>
