var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bundle-table"},[_c('Margins',[_c('div',{staticClass:"bundle-table__button-group"},[_c('Button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.addAsset('products')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('AddSVG',{staticClass:"icon"})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('ADD_PRODUCT'))+" ")]),_c('Button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.addAsset('services')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('AddSVG',{staticClass:"icon"})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('ADD_SERVICE'))+" ")])],1),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table--bundle-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('DESCRIPTION')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('UNITS_IN_BUNDLE')))]),_c('th',[_vm._v(_vm._s(_vm.$t('MANUFACTURING_COST_PER_UNIT')))]),_c('th',[_vm._v(_vm._s(_vm.$t('DEALER_COST_PER_UNIT')))]),_c('th',[_vm._v(_vm._s(_vm.$t('RETAIL_VALUE')))]),_c('th')])]),_c('tbody',[_vm._l((_vm.bundle.products),function(product,productIndex){return _c('tr',{key:`product-${productIndex}`},[_c('td',[_c('Input',{attrs:{"id":`product-name-${_vm.index}-${productIndex}`,"name":"nameProduct","value":product.name || `${_vm.$t('PRODUCT')} ${productIndex + 1}`},on:{"input":(e) => _vm.updateAsset(e, 'name', productIndex, 'products')}})],1),_c('td',[_c('Input',{attrs:{"type":"number","textAlign":"center","id":`product-units-${_vm.index}-${productIndex}`,"name":"unitsProduct","value":product.units},on:{"input":(e) => _vm.updateAsset(e, 'units', productIndex, 'products')}})],1),_c('td',[_c('Input',{attrs:{"type":"number","prefix":_vm.$t('CURRENCY'),"id":`product-manufacturingCostPerUnit-${_vm.index}-${productIndex}`,"name":"manufacturingCostPerUnitProduct","value":product.manufacturingCostPerUnit},on:{"input":(e) =>
                    _vm.updateAsset(
                      e,
                      'manufacturingCostPerUnit',
                      productIndex,
                      'products',
                    )}})],1),_c('td',[_c('Input',{attrs:{"type":"number","prefix":_vm.$t('CURRENCY'),"id":`product-dealerCostPerUnit-${_vm.index}-${productIndex}`,"name":"dealerCostPerUnitProduct","value":product.dealerCostPerUnit},on:{"input":(e) =>
                    _vm.updateAsset(
                      e,
                      'dealerCostPerUnit',
                      productIndex,
                      'products',
                    )}})],1),_c('td',[_c('Input',{attrs:{"type":"number","prefix":_vm.$t('CURRENCY'),"id":`product-retailValue-${_vm.index}-${productIndex}`,"name":"retailValueProduct","value":product.retailValue},on:{"input":(e) =>
                    _vm.updateAsset(e, 'retailValue', productIndex, 'products')}})],1),_c('td',{staticClass:"no-padding"},[_c('Button',{attrs:{"variant":"ghost","title":_vm.$t('REMOVE_PRODUCT')},on:{"click":function($event){return _vm.removeAsset(productIndex, 'products')}}},[_c('RemoveSVG',{staticClass:"icon"})],1)],1)])}),_vm._l((_vm.bundle.services),function(service,serviceIndex){return _c('tr',{key:`service-${serviceIndex}`},[_c('td',[_c('Input',{attrs:{"id":`service-name-${_vm.index}-${serviceIndex}`,"name":"nameService","value":service.name || `${_vm.$t('SERVICE')} ${serviceIndex + 1}`},on:{"input":(e) => _vm.updateAsset(e, 'name', serviceIndex, 'services')}})],1),_c('td',[_c('Input',{attrs:{"type":"number","textAlign":"center","id":`service-units-${_vm.index}-${serviceIndex}`,"name":"unitsService","value":service.units},on:{"input":(e) => _vm.updateAsset(e, 'units', serviceIndex, 'services')}})],1),_c('td'),_c('td',[_c('Input',{attrs:{"type":"number","prefix":_vm.$t('CURRENCY'),"id":`service-dealerCostPerUnit-${_vm.index}-${serviceIndex}`,"name":"dealerCostPerUnitService","value":service.dealerCostPerUnit},on:{"input":(e) =>
                    _vm.updateAsset(
                      e,
                      'dealerCostPerUnit',
                      serviceIndex,
                      'services',
                    )}})],1),_c('td',[_c('Input',{attrs:{"type":"number","prefix":_vm.$t('CURRENCY'),"id":`service-retailValue-${_vm.index}-${serviceIndex}`,"name":"retailValueService","value":service.retailValue},on:{"input":(e) =>
                    _vm.updateAsset(e, 'retailValue', serviceIndex, 'services')}})],1),_c('td',{staticClass:"no-padding"},[_c('Button',{attrs:{"variant":"ghost","title":_vm.$t('REMOVE_SERVICE')},on:{"click":function($event){return _vm.removeAsset(serviceIndex, 'services')}}},[_c('RemoveSVG',{staticClass:"icon"})],1)],1)])})],2),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('TOTAL')))]),_c('td'),_c('td',[_c('span',{staticClass:"text-small text-grey"},[_vm._v(_vm._s(_vm.$t('CURRENCY')))]),_vm._v(" "+_vm._s(_vm.calculateManufacturingCostPerUnit())+" ")]),_c('td',[_c('span',{staticClass:"text-small text-grey"},[_vm._v(_vm._s(_vm.$t('CURRENCY')))]),_vm._v(" "+_vm._s(_vm.calculateDealerCostPerUnit())+" ")]),_c('td',[_c('span',{staticClass:"text-small text-grey"},[_vm._v(_vm._s(_vm.$t('CURRENCY')))]),_vm._v(" "+_vm._s(_vm.calculateRetailValue())+" ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }