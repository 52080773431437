<template>
  <div id="app">
    <Header />
    <main>
      <Container>
        <Margins>
          <Summary />

          <ul class="margins__double">
            <li v-for="(bundle, index) in bundles" :key="index">
              <Bundle :index="index" :bundle="bundle" />
            </li>
          </ul>

          <AddBundle />
        </Margins>
      </Container>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Header, AddBundle, Container, Margins } from '@/components';
import { Bundle, Summary } from '@/containers';

export default {
  name: 'app',
  components: {
    Header,
    AddBundle,
    Container,
    Margins,
    Summary,
    Bundle,
  },
  computed: {
    ...mapState(['bundles']),
  },
};
</script>
