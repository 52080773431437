<template>
  <div class="bundle-table">
    <Margins>
      <div class="bundle-table__button-group">
        <Button @click="addAsset('products')" size="small">
          <template v-slot:icon>
            <AddSVG class="icon" />
          </template>
          {{ $t('ADD_PRODUCT') }}
        </Button>
        <Button @click="addAsset('services')" size="small">
          <template v-slot:icon>
            <AddSVG class="icon" />
          </template>
          {{ $t('ADD_SERVICE') }}
        </Button>
      </div>

      <div class="table-responsive">
        <table class="table table--bundle-table">
          <thead>
            <tr>
              <th>{{ $t('DESCRIPTION') }}</th>
              <th class="text-center">{{ $t('UNITS_IN_BUNDLE') }}</th>
              <th>{{ $t('MANUFACTURING_COST_PER_UNIT') }}</th>
              <th>{{ $t('DEALER_COST_PER_UNIT') }}</th>
              <th>{{ $t('RETAIL_VALUE') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, productIndex) in bundle.products"
              :key="`product-${productIndex}`"
            >
              <td>
                <Input
                  :id="`product-name-${index}-${productIndex}`"
                  name="nameProduct"
                  :value="
                    product.name || `${$t('PRODUCT')} ${productIndex + 1}`
                  "
                  @input="
                    (e) => updateAsset(e, 'name', productIndex, 'products')
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  textAlign="center"
                  :id="`product-units-${index}-${productIndex}`"
                  name="unitsProduct"
                  :value="product.units"
                  @input="
                    (e) => updateAsset(e, 'units', productIndex, 'products')
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  :prefix="$t('CURRENCY')"
                  :id="`product-manufacturingCostPerUnit-${index}-${productIndex}`"
                  name="manufacturingCostPerUnitProduct"
                  :value="product.manufacturingCostPerUnit"
                  @input="
                    (e) =>
                      updateAsset(
                        e,
                        'manufacturingCostPerUnit',
                        productIndex,
                        'products',
                      )
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  :prefix="$t('CURRENCY')"
                  :id="`product-dealerCostPerUnit-${index}-${productIndex}`"
                  name="dealerCostPerUnitProduct"
                  :value="product.dealerCostPerUnit"
                  @input="
                    (e) =>
                      updateAsset(
                        e,
                        'dealerCostPerUnit',
                        productIndex,
                        'products',
                      )
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  :prefix="$t('CURRENCY')"
                  :id="`product-retailValue-${index}-${productIndex}`"
                  name="retailValueProduct"
                  :value="product.retailValue"
                  @input="
                    (e) =>
                      updateAsset(e, 'retailValue', productIndex, 'products')
                  "
                />
              </td>
              <td class="no-padding">
                <Button
                  @click="removeAsset(productIndex, 'products')"
                  variant="ghost"
                  :title="$t('REMOVE_PRODUCT')"
                >
                  <RemoveSVG class="icon" />
                </Button>
              </td>
            </tr>
            <tr
              v-for="(service, serviceIndex) in bundle.services"
              :key="`service-${serviceIndex}`"
            >
              <td>
                <Input
                  :id="`service-name-${index}-${serviceIndex}`"
                  name="nameService"
                  :value="
                    service.name || `${$t('SERVICE')} ${serviceIndex + 1}`
                  "
                  @input="
                    (e) => updateAsset(e, 'name', serviceIndex, 'services')
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  textAlign="center"
                  :id="`service-units-${index}-${serviceIndex}`"
                  name="unitsService"
                  :value="service.units"
                  @input="
                    (e) => updateAsset(e, 'units', serviceIndex, 'services')
                  "
                />
              </td>
              <td />
              <td>
                <Input
                  type="number"
                  :prefix="$t('CURRENCY')"
                  :id="`service-dealerCostPerUnit-${index}-${serviceIndex}`"
                  name="dealerCostPerUnitService"
                  :value="service.dealerCostPerUnit"
                  @input="
                    (e) =>
                      updateAsset(
                        e,
                        'dealerCostPerUnit',
                        serviceIndex,
                        'services',
                      )
                  "
                />
              </td>
              <td>
                <Input
                  type="number"
                  :prefix="$t('CURRENCY')"
                  :id="`service-retailValue-${index}-${serviceIndex}`"
                  name="retailValueService"
                  :value="service.retailValue"
                  @input="
                    (e) =>
                      updateAsset(e, 'retailValue', serviceIndex, 'services')
                  "
                />
              </td>
              <td class="no-padding">
                <Button
                  @click="removeAsset(serviceIndex, 'services')"
                  variant="ghost"
                  :title="$t('REMOVE_SERVICE')"
                >
                  <RemoveSVG class="icon" />
                </Button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-right">{{ $t('TOTAL') }}</td>
              <td />
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateManufacturingCostPerUnit() }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateDealerCostPerUnit() }}
              </td>
              <td>
                <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
                {{ calculateRetailValue() }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Margins>
  </div>
</template>

<script>
import { Input, Button, Margins } from '@/components';
import { types } from '@/store';
import { mapMutations, mapActions } from 'vuex';
import RemoveSVG from '@/assets/images/times-circle-solid.svg';
import AddSVG from '@/assets/images/plus-circle-solid.svg';
import {
  calculateManufacturingCostPerUnit,
  calculateDealerCostPerUnit,
  calculateRetailValue,
} from '@/calculator';

export default {
  name: 'BundleTable',
  components: {
    Input,
    Button,
    Margins,
    RemoveSVG,
    AddSVG,
  },
  props: {
    index: Number,
    bundle: Object,
  },
  methods: {
    ...mapActions([types.UPDATE_BUNDLE_ASSET, types.REMOVE_BUNDLE_ASSET]),
    ...mapMutations([types.ADD_BUNDLE_ASSET]),
    addAsset(type) {
      this.ADD_BUNDLE_ASSET({ index: this.index, type });
    },
    removeAsset(assetIndex, type) {
      this.REMOVE_BUNDLE_ASSET({ index: this.index, assetIndex, type });
    },
    updateAsset(value, prop, assetIndex, type) {
      this.UPDATE_BUNDLE_ASSET({
        index: this.index,
        assetIndex,
        prop,
        value,
        type,
      });
    },
    calculateManufacturingCostPerUnit() {
      return calculateManufacturingCostPerUnit(this.bundle);
    },
    calculateDealerCostPerUnit() {
      return calculateDealerCostPerUnit(this.bundle);
    },
    calculateRetailValue() {
      return calculateRetailValue(this.bundle);
    },
  },
};
</script>

<style lang="scss">
.bundle-table__button-group {
  * + * {
    margin-left: calc($grid-gutter-width / 2);
  }
}
</style>
