<template>
  <Button @click="addBundle">
    <template v-slot:icon>
      <AddSVG class="icon" />
    </template>
    {{ $t('ADD_BUNDLE') }}
  </Button>
</template>

<script>
import { types } from '@/store';
import { mapActions } from 'vuex';
import { Button } from '@/components';
import AddSVG from '@/assets/images/plus-circle-solid.svg';

export default {
  name: 'AddBundle',
  components: {
    Button,
    AddSVG,
  },
  methods: {
    ...mapActions([types.ADD_BUNDLE]),
    addBundle() {
      this.ADD_BUNDLE();
    },
  },
};
</script>
