import inRange from 'lodash/inRange';

export const BANK_PRICING_SWEDEN = (months) => {
  if (inRange(months, 0, 13)) {
    return 0.03;
  } else if (inRange(months, 13, 25)) {
    return 0.06;
  } else if (inRange(months, 25, 37)) {
    return 0.09;
  } else if (inRange(months, 37, 49)) {
    return 0.12;
  } else if (inRange(months, 49, 61)) {
    return 0.15;
  } else if (inRange(months, 61, 73)) {
    return 0.18;
  } else {
    return 0.18;
  }
};

export const BANK_PRICING_DENMARK = (months) => {
  if (inRange(months, 0, 25)) {
    return 0.07;
  } else if (inRange(months, 25, 49)) {
    return 0.14;
  } else if (inRange(months, 49, 60)) {
    return 0.21;
  } else if (inRange(months, 60, 61)) {
    return 0.175;
  } else if (inRange(months, 61, 73)) {
    return 0.21;
  }
};

export const EMPTY_ASSET = (type) => ({
  name: '',
  units: '0',
  ...(type === 'products' && { manufacturingCostPerUnit: '0' }),
  dealerCostPerUnit: '0',
  retailValue: '0',
});

export const EMPTY_BUNDLE = () => ({
  products: [],
  services: [],
  directSubsidyFromManufacturer: '0',
  financingCostCompensation: 0,
  timeToRenew: '36',
  financingPeriod: '45',
  inAdvanceCommittedPriceForUsedProduct: '0',
  outstandingLoanOnRenewal: 0,
  monthlyPaymentForCustomer: 0,
  outstandingLoanOnRenewalCompensation: 0,
});
