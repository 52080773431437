var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'input',
    { 'has-error': _vm.error },
    { 'has-prefix': _vm.prefix },
    { 'has-suffix': _vm.suffix },
    { [`input--${_vm.variant}`]: !!_vm.variant },
  ]},[(_vm.label)?_c('label',{staticClass:"input__label",attrs:{"for":_vm.id || _vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.info)?_c('p',{staticClass:"input__info"},[_vm._v(_vm._s(_vm.info))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input__content"},[(_vm.prefix)?_c('span',{staticClass:"input__prefix",domProps:{"innerHTML":_vm._s(_vm.prefix)}}):_vm._e(),_c('input',{ref:"input",class:['input__input', { [`text-${_vm.textAlign}`]: !!_vm.textAlign }],attrs:{"id":_vm.id || _vm.name,"type":_vm.type,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}}),(_vm.suffix)?_c('span',{staticClass:"input__suffix",domProps:{"innerHTML":_vm._s(_vm.suffix)}}):_vm._e()]),(_vm.error)?_c('p',{staticClass:"input__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }