<template>
  <header class="header">
    <Container class="header__container">
      <div class="header__logo-wrapper">
        <StihlLogoSVG v-if="THEME === 'stihl'" class="header__logo" />
        <HusqvarnaLogoSVG
          v-else-if="THEME === 'husqvarna'"
          class="header__logo"
        />
        <FairownLogoSVG v-else class="header__logo" />
        <span class="header__logo-text">{{ $t('PROJECT_NAME') }}</span>
      </div>
      <div class="header__input-group">
        <Input
          ref="copyInput"
          name="url"
          variant="independent"
          :value="`${window.location.origin}/index.html?data=${urlParams}`"
          readonly
        />
        <Button @click="copyToClipboard" size="small">
          {{ isCopied ? $t('COPIED') : $t('COPY_URL') }}
        </Button>
      </div>
    </Container>
  </header>
</template>

<script>
import FairownLogoSVG from '@/assets/images/fairown-logo.svg';
import StihlLogoSVG from '@/assets/images/stihl-logo.svg';
import HusqvarnaLogoSVG from '@/assets/images/husqvarna-logo.svg';
import { Container, Button } from '@/components';
import Input from './Input.vue';
import { mapState } from 'vuex';

export default {
  name: 'Header',
  components: {
    FairownLogoSVG,
    StihlLogoSVG,
    HusqvarnaLogoSVG,
    Container,
    Button,
    Input,
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    copyToClipboard() {
      const copyText = this.$refs.copyInput.$refs.input;

      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand('copy');

      this.isCopied = true;
      setTimeout(() => (this.isCopied = false), 3000);
    },
  },
  computed: {
    ...mapState(['urlParams']),
    THEME() {
      return process.env.VUE_APP_THEME;
    },
  },
};
</script>

<style lang="scss">
.header {
  background: $color-white;
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include min-width(sm) {
    flex-direction: row;
  }
}

.header__logo-wrapper {
  display: flex;
  align-items: center;
}

.header__logo {
  height: 2.5rem;
  max-width: rem(200px);
  width: auto;

  @include min-width(sm) {
    height: 3.5rem;
  }
}

.header__logo-text {
  position: relative;
  font-size: $font-size-small;
  color: $color-grey-dark;
  padding-left: 1rem;
  margin-left: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $color-grey-dark;
  }
}

.header__input-group {
  display: flex;

  .input__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
