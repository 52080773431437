import Vue from 'vue';
import 'focus-visible';

import App from './App.vue';
import store from './store';
import i18n from './i18n';

const theme = process.env.VUE_APP_THEME;

import './assets/scss/setup.scss';
if (theme) {
  require(`./assets/scss/variables-${theme}.scss`);
}
import './assets/scss/main.scss';

Vue.prototype.window = window; // This is needed for using window object inside <template>

Vue.config.productionTip = false;

new Vue({
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
