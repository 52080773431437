<template>
  <div class="bundle-summary">
    <div class="table-responsive">
      <table class="table table--inputs table--bundle-summary">
        <tbody>
          <tr>
            <th>{{ $t('DIRECT_SUBSIDY_FROM_MANUFACTURER') }}</th>
            <td class="bg-grey">
              <Input
                type="number"
                size="small"
                :prefix="$t('CURRENCY')"
                :id="`directSubsidyFromManufacturer-${index}`"
                name="directSubsidyFromManufacturer"
                :value="bundle.directSubsidyFromManufacturer"
                @input="(e) => change(e, 'directSubsidyFromManufacturer')"
              />
            </td>
          </tr>
          <tr>
            <th>{{ $t('FINANCING_COST_COMPENSATION') }}</th>
            <td>
              <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
              {{ bundle.financingCostCompensation }}
            </td>
          </tr>
          <tr v-if="index >= 1">
            <th>{{ $t('OUTSTAND_LOAN_ON_RENEWAL_COMPENSATION') }}</th>
            <td>
              <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
              {{ bundle.outstandingLoanOnRenewalCompensation }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('TIME_TO_RENEW') }}</th>
            <td class="bg-grey">
              <Input
                type="number"
                size="small"
                :suffix="$t('MONTHS')"
                :id="`timeToRenew-${index}`"
                name="timeToRenew"
                :value="bundle.timeToRenew"
                @input="(e) => change(e, 'timeToRenew')"
              />
            </td>
          </tr>
          <tr>
            <th>{{ $t('FINANCING_PERIOD') }}</th>
            <td class="bg-grey">
              <Input
                type="number"
                size="small"
                :suffix="$t('MONTHS')"
                :id="`financingPeriod-${index}`"
                name="financingPeriod"
                :value="bundle.financingPeriod"
                @input="(e) => change(e, 'financingPeriod')"
              />
            </td>
          </tr>
          <tr>
            <th>{{ $t('IN_ADVANCED_COMMITTED_PRICE_FOR_USED_PRODUCT') }}</th>
            <td class="bg-grey">
              <Input
                type="number"
                size="small"
                :prefix="$t('CURRENCY')"
                :id="`inAdvanceCommittedPriceForUsedProduct-${index}`"
                name="inAdvanceCommittedPriceForUsedProduct"
                :value="bundle.inAdvanceCommittedPriceForUsedProduct"
                @input="
                  (e) => change(e, 'inAdvanceCommittedPriceForUsedProduct')
                "
              />
            </td>
          </tr>
          <tr>
            <th>{{ $t('OUTSTANDING_LOAN_ON_RENEWAL') }}</th>
            <td>
              <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
              {{ bundle.outstandingLoanOnRenewal }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t('MONTHLY_PAYMENT_FOR_CUSTOMER') }}</td>
            <td class="h4">
              <span class="text-small text-grey">{{ $t('CURRENCY') }}</span>
              {{ bundle.monthlyPaymentForCustomer }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { Input } from '@/components';
import { types } from '@/store';
import { mapActions } from 'vuex';

export default {
  name: 'BundleSummary',
  components: {
    Input,
  },
  props: {
    index: Number,
    bundle: Object,
  },
  methods: {
    ...mapActions([types.UPDATE_BUNDLE]),
    change(value, prop) {
      this.UPDATE_BUNDLE({
        index: this.index,
        props: {
          [prop]: value,
        },
      });
    },
  },
};
</script>
