import Vue from 'vue';
import Vuex from 'vuex';
import merge from 'lodash/merge';

import { EMPTY_BUNDLE, EMPTY_ASSET } from '@/constants';
import {
  calculateFinancingCostCompensation,
  calculateOutstandingLoanOnRenewal,
  calculateMonthlyPaymentForCustomer,
  calculateOutstandingLoanOnRenewalCompensation,
} from '@/calculator';
import base64 from '@/base64';

Vue.use(Vuex);

const addUrlParams = (data) => {
  window.history.replaceState(null, null, `?data=${data}`);
};

const getUrlParams = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get('data');
};

export const types = {
  ADD_BUNDLE: 'ADD_BUNDLE',
  REMOVE_BUNDLE: 'REMOVE_BUNDLE',
  UPDATE_BUNDLE: 'UPDATE_BUNDLE',
  ADD_BUNDLE_ASSET: 'ADD_BUNDLE_ASSET',
  REMOVE_BUNDLE_ASSET: 'REMOVE_BUNDLE_ASSET',
  UPDATE_BUNDLE_ASSET: 'UPDATE_BUNDLE_ASSET',
  CALCULATE_BUNDLE: 'CALCULATE_BUNDLE',
};

const {
  ADD_BUNDLE,
  REMOVE_BUNDLE,
  UPDATE_BUNDLE,
  ADD_BUNDLE_ASSET,
  REMOVE_BUNDLE_ASSET,
  UPDATE_BUNDLE_ASSET,
  CALCULATE_BUNDLE,
} = types;

export default new Vuex.Store({
  state: {
    bundles: getUrlParams() ? JSON.parse(base64.urlDecode(getUrlParams())) : [],
    urlParams: getUrlParams(),
  },
  actions: {
    [ADD_BUNDLE]({ commit, state, dispatch }) {
      commit(ADD_BUNDLE);
      dispatch(CALCULATE_BUNDLE, {
        index: state.bundles.length - 1,
        isNew: true,
      });
    },
    [REMOVE_BUNDLE]({ commit, dispatch }, index) {
      commit(REMOVE_BUNDLE, index);
      dispatch(CALCULATE_BUNDLE, {
        index: index === 0 ? index : index - 1,
        isRemove: true,
      });
    },
    [UPDATE_BUNDLE_ASSET]({ commit, dispatch }, data) {
      commit(UPDATE_BUNDLE_ASSET, data);
      dispatch(CALCULATE_BUNDLE, { index: data.index });
    },
    [REMOVE_BUNDLE_ASSET]({ commit, dispatch }, data) {
      commit(REMOVE_BUNDLE_ASSET, data);
      dispatch(CALCULATE_BUNDLE, { index: data.index });
    },
    [UPDATE_BUNDLE]({ commit, dispatch }, data) {
      commit(UPDATE_BUNDLE, data);
      dispatch(CALCULATE_BUNDLE, { index: data.index });
    },
    [CALCULATE_BUNDLE]({ commit, state }, { index, isNew, isRemove }) {
      const bundle = state.bundles[index];

      if (!isRemove) {
        commit(UPDATE_BUNDLE, {
          index,
          props: {
            financingCostCompensation:
              calculateFinancingCostCompensation(bundle),
            outstandingLoanOnRenewal: calculateOutstandingLoanOnRenewal(bundle),
            monthlyPaymentForCustomer:
              calculateMonthlyPaymentForCustomer(bundle),
          },
        });
      }

      if (state.bundles.length > 1) {
        if (!isNew && !!state.bundles[index + 1]) {
          commit(UPDATE_BUNDLE, {
            index: index + 1,
            props: {
              outstandingLoanOnRenewalCompensation:
                calculateOutstandingLoanOnRenewalCompensation(
                  state.bundles[index],
                ),
            },
          });
        }

        if (isNew) {
          commit(UPDATE_BUNDLE, {
            index: index,
            props: {
              outstandingLoanOnRenewalCompensation:
                calculateOutstandingLoanOnRenewalCompensation(
                  state.bundles[index - 1],
                ),
            },
          });
        }
      }
    },
  },
  mutations: {
    [ADD_BUNDLE](state, bundle = EMPTY_BUNDLE()) {
      state.bundles = state.bundles.concat([bundle]);
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
    [REMOVE_BUNDLE](state, index) {
      state.bundles = state.bundles.filter((_, i) => i !== index);
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
    [UPDATE_BUNDLE](state, { index, props }) {
      state.bundles[index] = merge(state.bundles[index], props);
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
    [ADD_BUNDLE_ASSET](state, { index, type }) {
      state.bundles[index][type] = state.bundles[index][type].concat([
        EMPTY_ASSET(type),
      ]);
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
    [REMOVE_BUNDLE_ASSET](state, { index, assetIndex, type }) {
      state.bundles[index][type] = state.bundles[index][type].filter(
        (_, i) => i !== assetIndex,
      );
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
    [UPDATE_BUNDLE_ASSET](state, { index, assetIndex, prop, value, type }) {
      state.bundles[index][type][assetIndex][prop] = value;
      state.urlParams = base64.urlEncode(JSON.stringify(state.bundles));
      addUrlParams(state.urlParams);
    },
  },
});
