<template>
  <button
    :type="type"
    :class="[
      'button',
      { [`button--${variant}`]: !!variant },
      { [`button--${size}`]: !!size },
      { ['button--block']: block },
    ]"
    @click="$emit('click', $event)"
    :disabled="disabled || loading"
  >
    <span class="button__content">
      <slot name="icon" />
      <span class="button__text">
        <slot />
      </span>
      <LoadingSVG class="icon" v-if="loading" />
    </span>
  </button>
</template>

<script>
import LoadingSVG from '@/assets/images/loading.svg';

export default {
  name: 'Button',
  components: {
    LoadingSVG,
  },
  props: {
    type: {
      validator: function (value) {
        return ['button', 'submit'].includes(value);
      },
      default: 'button',
    },
    disabled: Boolean,
    loading: Boolean,
    variant: {
      validator: function (value) {
        return ['link', 'inverse', 'ghost'].includes(value);
      },
    },
    size: {
      validator: function (value) {
        return ['small', 'large'].includes(value);
      },
    },
    block: Boolean,
  },
};
</script>

<style lang="scss">
.button {
  background: $color-primary;
  border: 1px solid $color-primary;
  border-radius: $border-radius;
  padding: rem(12px) rem(32px);
  color: $color-white;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  > * {
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }
}

.button--link {
  background: transparent;
  border-color: transparent;
  color: $color-grey-dark;
  padding: 0;
  text-transform: uppercase;
  font-size: $font-size-extra-small;
  letter-spacing: 1px;

  &:hover {
    box-shadow: none;
    color: $color-black;
  }
}

.button--inverse {
  background: $color-white;
  color: $color-primary;

  &:hover {
    background: $color-primary;
    color: $color-white;
  }
}

.button--ghost {
  background: transparent;
  border-color: transparent;
  border-radius: 99em;
  color: $color-black;
  padding: rem(12px);
  text-transform: uppercase;
  font-size: $font-size-small;

  &:hover {
    background: $color-primary-light;
    color: $color-black-light;
    opacity: 1;
  }

  .button--small & {
    padding: rem(8px);
  }

  .button--large & {
    padding: rem(20px);
  }
}

.button--small {
  padding: rem(8px) 1rem;
  font-size: $font-size-small;
}

.button--large {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
}

.button--block {
  display: block;
  width: 100%;
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__text {
  display: flex;
}

.icon + .button__text,
.button__text + .icon {
  margin-left: 0.5rem;
}
</style>
